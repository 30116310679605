import React from 'react'
import { graphql } from 'gatsby'
import Layout from 'gatsby-theme-psg/src/components/layout/Layout'
import Header from '../components/Header'
import Tickets from 'gatsby-theme-psg/src/components/event/tickets/Tickets'
import Lead from '../components/Lead';

export const TicketsPageTemplate = ({ title, content }) => {
  return (
    <React.Fragment>
      <Header background='rgba(255,120,0,0.85)'>
        <Lead title={title} content={content}/>
      </Header>
      <div className="container mb-5 pb-5" style={{marginTop: '-100px'}}>
        <Tickets />
      </div>
    </React.Fragment>
  )
}

const TicketsPage = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout title={post.frontmatter.title} description={post.frontmatter.description}>
      <TicketsPageTemplate title={post.frontmatter.title} content={post.html} />
    </Layout>
  )
}

export default TicketsPage

export const pageQuery = graphql`
  query TicketsPage {
    markdownRemark(frontmatter: { templateKey: { eq: "tickets-page" } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`
